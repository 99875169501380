import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <p>Hidemaro Fujinami</p>
    <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
    </div>
  </Layout>
)

export default IndexPage
